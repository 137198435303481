import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import InhomeServiceForm from "../containers/InhomeServiceForm";
// import CustomerInformationForm from "../containers/CustomerInformationForm";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Navigate to="/inhome-service" />} />
        <Route path="/inhome-service" element={<InhomeServiceForm />} />
        {/* <Route path="/customer-information" element={<CustomerInformationForm />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
