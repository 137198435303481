import { Box, TextField } from "@mui/material";
import React from "react";

const SingleInput = ({
  label,
  name,
  value,
  handleOnChange,
  required=false
}) => {
  return (
    <Box sx={{ mt: { md: "2rem", xs: "1rem" } }}>
      {/* <Typography sx={{ color: "primary.light", fontWeight: "bold" }}>
        {label}
        <span style={{ color: "red" }}> *</span>
      </Typography> */}
      {/* <TextField
        placeholder={placeholder}
        required
        type={type}
        name={name}
        value={data}
        onChange={handleOnChange}
        fullWidth
        size="small"
        sx={{ bgcolor: "#fff", borderRadius: "0.5rem", mt: "1rem" }}
      /> */}
      <TextField
        onChange={handleOnChange}
        id="outlined-error-helper-text"
        label={label}
        value={value}
        variant="filled"
        name={name}
        size={"small"}
        fullWidth
        sx={{ marginY: "0.5rem" }}
        required={required}
        InputLabelProps={{
          style: {
            color: "#555",
          },
        }}
        InputProps={{ style: { backgroundColor: "#fff" } }}
      />
    </Box>
  );
};

export default SingleInput;
