import {
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SingleInput from "../components/SingleInput";
import { LoadingButton } from "@mui/lab";
import styled from "@emotion/styled";
import useAxios from "../hooks/useAxios";
import { useMutation } from "react-query";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


const SubmitButton = styled(LoadingButton)(
  () => `
  &:hover {
    background-color: gray;
    transform: translateY(-2px);
    transition: all 0.1s linear;
  }
  `
);

const InhomeServiceForm = () => {
  const api = useAxios();
  // const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState({
    name: "",
    phone: "",
    address: "",
    township: "",
    product: "",
    issue_error: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const MySwal = withReactContent(Swal);

  const { mutate, isLoading } = useMutation(
    () => {
      return api.post("/api/inhomeservice", data, {
        validateStatus: function (status) {
          return status <= 500;
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          MySwal.fire({
            title: <strong>Thank You!</strong>,
            html: <i>Your Submission has been sent.</i>,
            icon: "success",
            confirmButtonColor: "black",
          }).then((result) => {
            if (result.isConfirmed) {
              setData({
                name: "",
                phone: "",
                address: "",
                township: "",
                product: "",
                issue_error: "",
              });
              setIsChecked(false);
            }
          });
        } else {
          MySwal.fire({
            title: <strong>Sorry!</strong>,
            html: <i>{res.data.message[0]}</i>,
            icon: "error",
            confirmButtonColor: "black",
          });
          // console.log(res);
        }
      },
    }
  );

  const handleOnSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  return (
    <Box
      component="form"
      onSubmit={handleOnSubmit}
      sx={{
        position: "relative",
        color: "#fff",
        bgcolor: "#111",
        pt: "5rem",
        pb: "5rem",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          bgcolor: "#000",
          p: { md: "3rem", xs: "1rem" },
          borderRadius: "15px",
          fontWeight: "fontWeightBold",
          mx: "auto",
          width: { xs: "90%", md: "50%" },
        }}
      >
        <Box
          component="img"
          src="/assets/logo_white.png"
          sx={{
            display: "block",
            mx: "auto",
            mt: { md: "-5rem", xs: "-2.5rem" },
            width: { xs: "15rem", md: "20rem" },
            // filter: "drop-shadow(0px 10px 20px #fff)",
          }}
        />
        <Box
          sx={{
            bgcolor: "#fff",
            clipPath: {
              md: "ellipse(15% 18% at 50% 50%)",
              xs: "ellipse(30% 18% at 50% 50%)",
            },
            transform: "rotate(180deg)",
            opacity: "0.2",
          }}
        >
          .
        </Box>
        {/* <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { md: "2rem", xs: "1.5rem" },
            textAlign: "center",
            mt: "2rem",
          }}
        >
          Form Title
        </Typography> */}
        <Typography
          sx={{
            fontWeight: "bold",
            color: "secondary.main",
            textAlign: "center",
            mt: "2rem",
            width: { md: "60%", xs: "90%" },
            mx: "auto",
          }}
        >
          ဖြည့်သွင်းထားသော အချက်အလက်များကို In Home Service အတွက်သာ
          အသုံးပြုမည်ဖြစ်ပါသဖြင့် ပြည့်စုံစွာ ဖြည့်သွင်းပေးပါခင်ဗျာ။
        </Typography>

        <Box sx={{ mt: "2rem" }}>
          <SingleInput
            label="Customer Name / အမည်"
            name="name"
            value={data.name}
            handleOnChange={handleOnChange}
            required={true}
          />
          <SingleInput
            label="Phone Number / ဖုန်းနံပါတ်"
            name="phone"
            value={data.phone}
            handleOnChange={handleOnChange}
            required={true}
          />
          <SingleInput
            label="Address / နေရပ်လိပ်စာ"
            name="address"
            value={data.address}
            handleOnChange={handleOnChange}
          />
          <SingleInput
            label="Township / မြို့နယ်"
            name="township"
            value={data.township}
            handleOnChange={handleOnChange}
          />
          <SingleInput
            label="Product / ထုတ်ကုန်"
            name="product"
            value={data.product}
            handleOnChange={handleOnChange}
          />
          <Box sx={{ mt: { md: "2rem", xs: "1rem" } }}>
            {/* <Textarea
              required
              aria-label="about textarea"
              placeholder="Issue Error / အဆင်မပြေမှုများ *"
              minRows={10}
              name="issue_error"
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              value={data.issue_error}
              onChange={handleOnChange}
            /> */}

            <TextField
              name="issue_error"
              onChange={handleOnChange}
              value={data.issue_error}
              variant="filled"
              multiline
              rows={4}
              size="small"
              sx={{
                width: "100%",
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                bgcolor: "#fff",
              }}
              InputLabelProps={{ style: { color: "#555" } }}
              label="Issue Error / အဆင်မပြေမှုများ"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
            <Checkbox
              sx={{
                display: "inline-block",
                height: "30px",
                ml: "-0.5rem",
                color: "white",
              }}
              name="term_check"
              value={isChecked}
              checked={isChecked}
              required={true}
              onChange={() => setIsChecked(!isChecked)}
            />
            <span
              style={{
                width: "100%",
                marginTop: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              I agree to the <a style={{color: "inherit"}} href="https://www.samsung.com/mm/info/privacy"> Terms and Conditions </a>
            </span>
          </Box>
          <SubmitButton
            loading={isLoading}
            // disabled={!isChecked}
            type="submit"
            sx={{
              bgcolor: "secondary.main",
              mt: "2rem",
              mx: "auto",
              display: "block",
              color: "#000",
              fontWeight: "bold",
              px: "2rem",
            }}
          >
            Submit
          </SubmitButton>
        </Box>
      </Box>
    </Box>
  );
};

export default InhomeServiceForm;
